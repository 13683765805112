*{box-sizing:border-box; margin:0; padding:0;} 
.outer-box{width: auto; height:auto; background-color: transparent; display: flex; padding: 0;}
.navigationSidebar{display:none}
.blur-bg{background-color: #1B21314D; height:100%; width: 100%; position: fixed; top:0; left:0}
input:focus{outline:0;}
.loginpopup{
    border: 1px solid #1B21310D;
    border-radius: 11px;
    background-color: #fff;
    width: 100%;
    height: auto;
    max-width: 675px;
    min-height: 530px;
    position: fixed;
    top:50%; 
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 37px 110px 48px 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loginpopup div{
    width: 100%;
}
.loginpopup .signup_btn{margin-top:0;}
.popupclose{
    position: absolute;
    top:-15px;
    right:-15px;
    cursor: pointer;
    text-align: right;
}

.loginpopup h1{
    margin-top: 0;
    text-align: center;
    font-size:28px;
    font-weight:700;
    color:#1b2131;
    font-family: 'Circular Std';
    font-weight: 700;
    line-height: 28px;
    margin:0;
}
.loginpopup p{
    text-align: center;
    font-size: 14px;
    line-height: 19px; 
    margin-top: 10px;
    color:#1B2131B3;
    margin-top:16px;
    font-family: 'Circular Std Book';
    font-weight:normal;
}
.loginpopup form{
    margin:75px 0 30px 0;
}
.form-div{
    position: relative;
    margin-bottom: 16px;
}
.eye_button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none; 
    cursor: pointer;
    position: absolute;
    width: 24px; 
    height: 24px; 
    right: 10px;
    top: 50%;
    background-color: #fff;
    transform: translateY(-50%); 
    padding: 0; 
    z-index: 10; 
}

.loginpopup label{
    font-size: 14px;
    line-height:14px;
    margin: 0;
    position: absolute;
    left: 24px;
    top: 17px;
    transition: all .5s;
    color: #1B2131B3;
    font-family: 'Circular STd Book';
    font-weight: normal;
}
.loginpopup .form-div.active label{
    font-size: 12px;
    line-height:12px;
    top:-6px;
    background-color: #fff;
    padding:0 5px;
    left: 19px;
    color:#1B2131;

}

.loginpopup input{
    background-color: #F3F3F4;
    border-radius: 9px;
    border:0;
    font-size: 14px;
    line-height:22px;
    padding: 12px 24px;
    width: 100%;
    border:1px solid #F3F3F4;
    margin:0;
}
.loginpopup .form-div.active input{
    border:1px solid #1B21314D;
    background-color: transparent;
}
.loginpopup .form-div.error{
    font-size:inherit
}
.loginpopup .form-div.active.error input{
    border:1px solid #E02B27;
    background-color: transparent; 
}
.loginpopup .form-div.active.error label{
color:#E02B27;
}
.signup_btn, .otpsubmitbtn{
    background-color: #00b868;
    border-radius: 11px;
    border:0;
    width: 100%;
    font-size: 18px;
    line-height:18px;
    color:#fff;
    padding: 19px 0;
    margin:0;
}
.signup_btn{
    margin-top:50px
}
.signup_btn:hover, .otpsubmitbtn:hover{
    background-color:#00b868
}
p.or-signup{
    font-size:14px;
    line-height:14px;
    color:#1B2131CC;
    position: relative;
    margin: 0 auto;
    background-color: #fff;
    text-align: center;
    border: 1px solid #1B213133;
}

.or-signup span{
    padding:0 13px;
    display: flex;
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top:-7px;
    width: fit-content;
    margin:0 auto;
}

.continue-google{
    background-color: #F3F3F4;
    border: 0;
    width: 100%;
    margin-top:30px;
    padding:14px 0;
    border-radius: 9px;
    color:#1B2131;
    font-size:16px;
    border: 1px solid #fff;
}
.continue-google:hover{
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #1B21310D;
    background-color: #fff;
}
.continue-google img{
    margin-right:15px;
}

p.already-account{
    font-size: 14px;
    line-height: 14px;
    color:#1B2131CC;
    margin-top:13px;
    margin-bottom:0;
    font-weight:400;
}

p.already-account a{
    color:#1A4AB9;
    text-decoration: dashed;
}

p.already-account a:hover{
    border-bottom:1px solid #1A4AB9;
}
.loginpopup .error-div {
    display: block;
    color: #E02B27; 
    font-size: 12px; 
    margin-top: 4px; 
}
.loginpopup .form-div.error input {
    border: 2px solid #E02B27; 
    background-color: #FFF0F0;
}

/* Animation for Errors */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.loginpopup .error-div {
    animation: fadeIn 0.3s ease-out;
}
.loginpopup .form-div.active label,
.loginpopup .form-div.error label {
    top: -6px;
    background-color: #fff;
    padding: 0 5px;
    font-size: 12px;
    color: #1B2131;
}

.loginpopup .form-div.error label {
    color: #E02B27;
}


.forgot-ps{
    text-align: right;
    font-size:14px;
    line-height:14px;
    margin-bottom: 25px;
}
.forgot-ps {
    color:#1A4AB9;
}
.forgot-ps :hover{
    border-bottom:1px solid #1A4AB9;
}
.otp form{
    padding:0;
}
.otp{
    padding: 0 0 0;
    
}


.otp form .resend-link{
    color:#1A4AB9;
}
.otp form .resend-text{
    text-align: center;
    color:#1B2131CC;
    font-family: 'Circular STd Book';
    font-weight:normal;
    margin-bottom: 30px;
}
.otp form .resend-text span{
    color:#1A4AB9 !important;
    margin-left:5px;
}
.timer{
    color:#1A4AB9;
    text-align: center;
    display: block;
    font-family: 'Circular Std Book';
    font-weight: normal;
}

.otp .already-account{
    color:#1B2131CC;
}
.otp-input{
    display: flex;
    justify-content: space-between;
    margin:30px 0 35px 0;
}

.otp-input input{
    font-size:34px;
    line-height:34px;
    height:80px;
    width:80px;
    border:1px solid #1B21314D;
    border-radius: 23px;
    text-align: center;
    background-color: transparent;
}

.otp-input input::-webkit-outer-spin-button,
.otp-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.otp-input + .already-account{
    margin-bottom:40px;
}

.verifySuccess {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    text-align: center;
    padding: 20px;
    background-color: #fff; 
    border-radius: 10px;
    margin: 0 auto;
}

.verifySuccess img {
    width: 80px;
    height: 80px; 
    margin-bottom: 20px;
}

.verifySuccess h1 {
    font-size: 24px; 
    font-weight: 700;
    color: #1b2131; 
    margin-bottom: 10px;
}

.verifySuccess p {
    font-size: 14px;
    color: #1B2131B3; 
}

.verifySuccess .signup_btn {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    background-color: #00b868;
    color: #fff;
    border: none; 
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.verifySuccess .signup_btn:hover {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #1B21310D;
    background-color: #e1dada;
}
