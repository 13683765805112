.sidebar {
    width: 250px;
    height: 100%;
    background-color: #f2f2f2;
    padding: 0px;
}

.new-chat-button {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.menu-item {
    padding: 10px 0;
    cursor: pointer;
}

.chat-history h3 {
    margin-top: 20px;
}

.chat-history p {
    padding: 5px 0;
    font-size: 14px;
    color: #333;
}

.settingbtn button{
    border: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 18px 32px;
    border-top: 1px solid #1B21311A;
    background-color:#f0f0f0;
}
.settingbtn button img{
    margin-right:10px
}