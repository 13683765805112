*{box-sizing:border-box; margin:0; padding:0;}
.navigationSidebar{display:none}
.blurBg{background-color: #1b21314d; height:100%; width: 100%; position: fixed; top:0; left:0}
input:focus{outline:0;}
body.blurred {
    filter: blur(4px);
  }
.loginpopup{
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #1B21310D;
    border-radius: 11px;
    background-color: #fff;
    max-width: 100%;
    height: auto;
    max-width: 675px;
    width: 100%;
    position: fixed;
    top:50%; 
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 32px 110px ;
}
.popupclose{
    position: absolute;
    top:-15px;
    right:-15px;
    cursor: pointer;
}
.loginpopup h1{
    text-align: center;
    font-size:28px;
    font-weight:700;
    color:#1b2131;
    font-family: 'Circular Std';
    line-height:28px;
}
.loginpopup p{
    text-align: center;
    font-size: 14px;
    line-height: 14px; 
    margin:0;
    color:#1B2131B3;
    margin-top:16px;
    font-family: 'Circular Std Book';
    font-weight:normal; 
}
.loginpopup form{
    margin:30px 0 30px 0;
}
.form-div{
    position: relative;
    margin-bottom: 16px;
}
.eye_button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none; 
    cursor: pointer; 
    position: absolute;
    width: 24px; 
    height: 24px;
    right: 20px;
    top: 21px;
    background-color: transparent;
    transform: translateY(-50%); 
    padding: 0;
    z-index: 10; 
    box-shadow:unset;
    margin:0;

}


.loginpopup label{
    font-size: 14px;
    line-height:14px;
    margin: 0;
    position: absolute;
    left: 24px;
    top: 15px;
    transition: all .5s;
    color: #1B2131B3;
    font-family: 'Circular Std Book';
    font-weight: normal;

}
.loginpopup .form-div.active label{
    font-size: 12px;
    line-height:12px;
    top:-6px;
    background-color: #fff;
    padding:0 5px;
    left: 19px;
    color:#1B2131;

}

.loginpopup input{
    background-color: #F3F3F4;
    border-radius: 9px;
    border:0;
    font-size: 14px;
    line-height:22px;
    padding: 12px 24px;
    width: 100%;
    border:1px solid #F3F3F4;
    margin:0;
    font-family: 'Circular Std Book';
    font-weight:normal
}
.loginpopup .form-div.active input{
    border:1px solid #1B21314D;
    background-color: transparent;
}
.loginpopup .form-div.error{
    font-size:inherit
}
.loginpopup .form-div.active.error_show input{
    border:1px solid #E02B27;
    background-color: transparent; 
}
.loginpopup .form-div.active.error label{
color:#E02B27;
}
.login_btn{
    background-color: #00b868;
    border-radius: 11px;
    border:0;
    width: 100%;
    font-size: 18px;
    line-height:18px;
    color:#fff;
    padding: 19px 0;
    margin:0;
}

p.or-signup{
    font-size:14px;
    line-height:14px;
    color:#1B2131CC;
    position: relative;
    margin: auto auto  20px  auto;
    background-color: #fff;
    text-align: center;
    border: 1px solid #1B213133;
}

.or-signup span{
    padding:0 13px;
    display: flex;
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top:-7px;
    width: fit-content;
    margin:0 auto;
}

.continue-google{
    background-color: #F3F3F4;
    border: 0;
    width: 100%;
    margin-top:35px;
    padding:15px 0;
    border-radius: 9px;
    color:#1B2131;
    font-size:16px;
    border: 0;
    position: relative;
display: flex;
align-items: center;
justify-content: center;
box-shadow: unset;
border: 1px solid #F3F3F4;
}
.continue-google{position: absolute; width: 100%; left: 0; right:0; opacity: 0;}
.S9gUrf-YoZ4jf{opacity: 0;}
.continue-google:hover{
    box-shadow: 0px 3px 6px #0000001C;
    border: 1px solid #F3F3F4;
    background-color: #fff;
}
.continue-google img{
    margin-right:15px;
}

p.already-account{
    font-size: 14px;
    line-height: 14px;
    color:#1B2131CC;
    margin-top:13px;
    margin-bottom:0;
    font-weight:400;
}

p.already-account a{
    color:#1A4AB9;
    text-decoration: dashed;
}

p.already-account a:hover{
    border-bottom:1px solid #1A4AB9;
}

.error-div{
    font-size: 10px;
    color:#E02B27;
    display: flex;
    align-items: center;
    margin-top:7px;
    line-height: 10px;
    display:flex;
    padding-left:12px;
    
}

.error-div svg{
    margin-right: 3px;
}

.forgot-ps{
    text-align: right;
    font-size:14px;
    line-height:14px;
    margin-bottom: 25px;
    font-family: 'Circular Std Book';
    font-weight:normal;
    cursor: pointer;
    color:#1A4AB9;
    display: inline-block;
    float: right;
}
.forgot-ps:hover{
    text-decoration: underline;
}
.forgot-ps a{
    color:#1A4AB9;
    font-family: 'Circular Std Book';
    font-weight: normal; 
    text-decoration: none;
}
.forgot-ps a:hover{
    border-bottom:1px solid #1A4AB9;
}

.login_btn:hover{background-color: #00b868; box-shadow: unset;}