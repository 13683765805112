.chatmro p {
  font-size: 27px;
  line-height: 27px;
  color: #1b2131;
  font-family: 'Circular Std';
  font-weight: 900
}
.version-name {
  font-size: 9px;
  color: #ffffff;
  font-family: 'Circular Std';
  background-color: #3961be;
  margin-left: 5px;
  padding: 2.5px;
  border-radius: 4px;
}
.chat-layout {
  padding-left: 9px;
  height: 100%
}

.input-container {
  padding: 0;
  max-width: 744px;
  width: 100%;
  margin-bottom: 2vh;
}
.input-container .mistake-label{display:none;}
.chat-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-left: 0;
  margin-bottom: 10px;
  margin-right: 0;
  width: 100%;
  border-radius: 9px,
}

.chat-container .chat-container {
  max-width: 856px;
  width: 100%;
  margin: 0px auto
}

.topSection {
  padding: 24px 24px 0 24px
}

.new-chat {
  background-color: #727680;
  border-radius: 24px;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  font-family: 'Circular Std Book';
  font-weight: 400;
  border: 0;
  padding: 12px 30px 12px 22px;
  display: flex;
  align-items: center;
  margin-top: 45px
}

.new-chat span {
  font-size: 24px;
  line-height: 0;
  margin-right: 5px
}

.main-container {
  width: 100%;
  background-color: #fff;
  border-radius: 9px;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-box {
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
  margin-bottom: 12px
}

.user_icon {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #9adcef;
  color: #fff;
  font-family: 'Circular Std Book';
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  margin-right: 10px
}

.chat-icon {
  display: flex;
  align-items: center
}

.user_response {
  padding-left: 42px;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Circular Std Book';
  font-weight: 400
}

.upper-buttons {
  display: flex;
  flex-direction: column;
  margin-top: 24px
}

.chatMRO-Icon {
  display: flex;
  gap: 10px;
  margin-top: 0;
  flex-direction: column;
  margin-bottom: 50px
}
.chatMRO-Icon:last-child{
  margin-bottom:30px;
}
.chat-MRO {
  display: flex;
  align-items: center;
  margin-top: 32px;
}

.chat-MRO img {
  margin-right: 10px
}

.upper-buttons button {
  display: flex;
  align-items: center;
  border: 0;
  font-size: 16px;
  line-height: 16px;
  color: #1b2131E6;
  font-family: 'Circular Std Book';
  font-weight: 400;
  padding: 14px 24px
}

.upper-buttons button.active {
  background: #fff
}

.upper-buttons button.active svg path {
  fill: #1b2131 !important;
  stroke: #1b2131 !important
}

.upper-buttons svg {
  margin-right: 12px
}

.chat-MRO {
  min-width: 35px
}

.main-box {
  width: 100%;
  overflow-y: auto;
  padding-left: 0;
  padding-right: 0;
  -ms-overflow-style: auto;
  scrollbar-width: auto
}

.main-box div::-webkit-scrollbar {
  display: none;
}

.main-box::-webkit-scrollbar {
  width: 8px;
  background-color: #f9f9f9
}

.main-box::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 4px
}

.main-box::-webkit-scrollbar-thumb:hover {
  background-color: #a1a1a1
}

.main-box::-webkit-scrollbar-button {
  display: none
}

.font-bold {
  font-size: 14px;
  font-family: 'Circular Std';
  color: #1b2131;
  font-weight: 500
}

.prompt-bold {
  font-size: 14px;
  font-family: 'Circular Std';
  color: #1b2131e6;
  width:95%;
  max-width:95%;
}

.wrap-it {
  word-break: break-all;
  word-wrap: break-word
}

.product-dummy {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  position: sticky
}

.product_text {
  font-size: 12px;
  font-family: Circular Std medium;
  color: #1b2131
}

.specs {
  border: #f3f3f4 1.5px solid;
  border-radius: 12px;
  padding: 12px 0 12px 16px
}

.info :hover {
  background-color: #f3f3f4
}

.info {
  font-size: 12px;
  font-size: Circular Std Bold;
  font-weight: 700;
  color: #1b2131;
  display: flex;
  flex-direction: column;
  gap: 16px
}

.price {
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-family: Circular Std Book;
  margin-bottom: 27px
}

.count-btn {
  width: 18px;
  height: 18px
}

.cart {
  border: #00b868 1px solid;
  border-radius: 6px;
  width: 48px;
  height: 42px;
  padding: 10px
}

.cart-quote {
  display: flex;
  gap: 16px
}

.Quote-btn {
  width: 100%;
  font-size: 12px;
  font-family: Circular Std Medium;
  color: #fff;
  background-color: #00b868;
  border: none;
  border-radius: 6px
}

.pro-txt {
  font-size: 12px;
  font-family: Circular Std medium;
  color: #1b2131;
  margin-bottom: 21px
}

.Pro-img {
  display: flex;
  gap: 20px;
  margin-bottom: 20px
}

.pro-img6 {
  border: #1b21311a 1px solid;
  border-radius: 9px;
  padding: 5px
}

.pro-img7 {
  padding-top: 27px;
  padding-bottom: 11px;
  border: #1b21311a 1px solid;
  border-radius: 9px
}

.pro-img8 {
  border: #1b21311a 1px solid;
  border-radius: 9px
}

.pro-img9 {
  border: #1b21311a 1px solid;
  border-radius: 9px
}

.bottom-quote {
  font-size: 12px;
  font-family: Circular Std Medium;
  padding: 10px;
  color: #00b868;
  background-color: #fff;
  border: #00b868 1px solid;
  border-radius: 12px
}

body {
  overflow-x: hidden;
  overflow-y: hidden
}

.backend-answer {
  max-width: 60vw;
  overflow-x: auto;
  padding: auto 16px auto 10px
}

.answer-bold {
  white-space: pre-line;
  word-wrap: normal;
  letter-spacing: .5px;
  margin: 0;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  gap: 0;
  font-size: 1vw;
  font-family: Circular Std Book;
  color: #1b2131cc;
  width: 95%;
  max-width: 195%;
  overflow-x: auto;
  white-space: normal;
  -ms-overflow-style: auto;
  scrollbar-width: auto;
  padding-left: 42px
}

.answer-bold::-webkit-scrollbar {
  height: 8px;
  background-color: #f9f9f9
}

.answer-bold::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 4px
}

.answer-bold::-webkit-scrollbar-thumb:hover {
  background-color: #a1a1a1
}

.answer-bold::-webkit-scrollbar-button {
  display: none
}

.answer-bold table {
  width: auto;
  border-collapse: collapse
}

.answer-bold th {
  font-weight: 800;
  font-style: bold
}

.answer-bold td,
.answer-bold th {
  padding: 8px;
  border: 2px solid #dbdbdb;
  text-align: left
}

.answer-bold img {
  width: 200px;
  height: 175px;
  margin: 5px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 5px #f5f5f5
}

.answer-bold table {
  margin: 15px
}

.answer-bold p {
  white-space: pre-line;
  word-wrap: normal;
  letter-spacing: .5px;
  margin-bottom: 15px;
  margin-top: 0px;
  width: 100%;
}

.answer-bold li,
h1,
h2,
ul {
  margin-top: 5px;
  margin-bottom: 5px;
  word-wrap: normal;
  width: 100%
}

.alert-box {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #dedede;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1)
}

.backend-answer {
  max-width: 50rem;
  max-height: 15rem;
  overflow-y: auto;
  word-wrap: break-word;
  transition: .9s ease;
  margin-bottom: 0
}

.chat-response pre {
  white-space: pre-wrap
}

.developer-info {
  background-color: #d0cdcd smoke;
  color: #333;
  padding: 20px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #919191;
  overflow: auto;
  max-height: 200px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 14px
}

.fixed-toggle-container {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  padding: 10px 0
}

.library-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  max-width: 48rem;
  font-size: 13px
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  max-width: 48rem
}

.auth-buttons {
  display: flex;
  padding: 10px 15px 0 15px;
  position: absolute;
}

.icon {
  color: #76d0eb
}

.library-text {
  color: #4b5563
}

.feedback-buttons {
  display: flex;
  padding-left: 42px;
  margin-top: 1%;
}

.feedback-buttons button.selected img {
  filter: grayscale(0%);
  transform: scale(1.1);
}
.feedback-buttons button {
  border-radius: 50%;
  padding: 0;
  margin-left: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  display: flex;
  position: relative;
  background-color: #fff;
}
.feedback-buttons button svg{
  margin:0;
}

.feedback-buttons button:hover svg circle  {
  fill: #f5f5f6;
}
.feedback-buttons button:hover svg{
  background-color: #f5f5f6;
  border-radius: 50%;
}


.feedback-buttons button span {
  color: #fff;
  background: #1b2131;
  padding: 5px 15px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 14px;
  margin-top: 10px;
  display: none;
  transition: all ease .2s;
  position: absolute;
  top: 25px;
  z-index: 9999;
}

.feedback-buttons button:hover span {
  display: block
}

.feedback-buttons img {
  height: 20px;
  width: 20px
}

.feedback-input-form {
  width: 100%;
  display: flex;
  margin: 0;
  position: relative;
  padding: 0 20px 0 45px;
}

.feedback-input-form input {
  border-radius: 6px;
  border: 1px solid #1B21314D;
  font-size: 12px;
  line-height: 12px;
  color: #1b2131d3;
  font-family: "Circular Std Book";
  font-weight: normal;
  min-height: 64px;
  margin-bottom: 0;
}

.feedback-input-form button {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  position: absolute;
  height: 40px;
  width: 40px;
  right: 35px;
  top: 23px;
}

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;
  font-family: Arial, sans-serif;
  justify-content: center
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  height: auto;
  justify-content: end;
  margin-bottom: 20px;
}

.left-buttons {
  display: flex;
  align-items: center
}

.right-buttons {
  display: flex;
  align-items: center
}

.search-area {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%
}

.search-area h1,
.search-area h2 {
  margin: 10px 0;
  color: #333
}

.search-area input {
  width: 50%;
  padding: 15px;
  border-radius: 20px;
  border: 2px solid #cbd2d9;
  font-size: 18px;
  margin-top: 20px
}

.task-buttons {
  display: flex;
  margin-top: 40px;
  max-width: 744px;
  width: 100%;
  justify-content: space-between
}

.task-buttons button {
  padding: 12px 16px;
  margin-right: 10px;
  background-color: transparent;
  border: 1px solid #1b213133;
  cursor:default;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: #1b2131CC;
  margin: 0;
  width: auto;
  border-radius: 9px
}

.task-buttons button img {
  margin-right: 8px
}

.text {
  position: relative
}

.text input {
  display: flex;
  border-radius: 40px;
  border: 0;
  background-color: #f3f3f4;
  position: relative;
  margin-right: 40vw;
  width: 100%;
  box-shadow: 0 3px 6px #00000012;
  margin: 0;
  font-size: 16px;
  line-height: 16px;
  color: #1b2131;
  padding: 24px 48px 24px 48px
}

.text input::placeholder {
  color: #1b21314D
}

.mistake-label {
  width: 100%;
  max-width: 600px;
  text-align: center;
  font-size: 12px;
  color: #6b6b6b;
  background: transparent;
  padding: 5px 0 0 0;
  z-index: 999;
  font-family: 'Inter', Arial, sans-serif;
  margin:0 auto 0px auto;

}

input::placeholder {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif
}

.submit-btn {
  position: absolute;
  bottom: 7px;
  right: 15px;
  background-color: #e2e2e2;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer
}

.submit-btn>img {
  height: 28px;
  width: 28px;
  cursor: pointer
}

.hello {
  font-size: 36px;
  line-height: 36px;
  font-family: 'Circular Std';
  color: #1a4ab9;
  text-align: left;
  font-weight: 700;
  margin: 0
}

.caption {
  padding-bottom: 80px;
  color: #1b21314d;
  font-family: Circular Std medium;
  text-align: left;
  margin-top: 20px
}

.caption h2 {
  text-align: left;
  font-size: 36px;
  line-height: 36px;
  color: #1b21314D;
  font-family: 'Circular STd';
  font-weight: 500;
  margin: 0
}

.heading {
  padding-left: 15px
}



.button {
  align-items: right
}

.chat-container .main-container .chat-container .main-box::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width:1366px) {

  .chat-container .chat-container {
    max-width: 800px
  }
}

.follow-button {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  border-radius: 50%;
  width: 100px;
  padding:15px 15px;
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: bolder;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #e0e0e0!important;
}
.follow-button::before {
  content: "↓";
  font-size: 0.9rem;
  font-weight: bolder;
  color: #0a0a0a;
}

    iframe #container{
      width:100%
    }