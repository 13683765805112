/* PulseLoader.css */

.pulse-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  
  .pulse-loader span {
    width: 10px;
    height: 10px;
    background-color: #4caf50; /* Change color if needed */
    border-radius: 50%;
    animation: pulse 1.2s infinite ease-in-out;
  }
  
  .pulse-loader span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .pulse-loader span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes pulse {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  