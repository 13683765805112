.recent-chats h5{
  margin-bottom: 30px;
}
.recent-chats {
  height:100%; /* Limits height to 80% of the viewport height */
  overflow-y: auto; /* Adds vertical scroll if content exceeds max height */
  padding: 0;  
}
.sidebar_inner{
  height: 100%;
    overflow: hidden;
}
.recent-chats li.active {
  background-color: #f0f0f0;
  font-weight: 500;
}

  .recent-chats h3 {
    color: #d24545;
    margin-bottom: 60px;
  }
  .recent-chats h5, .recent-chats p{
    font-size:13px;
    line-height: 13px; 
    padding:0 32px;
    color:#1B213199;
    font-family: 'Circular Std Book';
    font-weight:normal;
    margin-bottom:15px;
  }
  .recent-chats-container{
    margin-top:15px;
    height: calc(117vh - 52.5vh);
    padding-bottom:12px;
  }
  .recent-chats ul {
    list-style: none;
    padding: 0;
  }
  .recent-chats ul{
    list-style-type: none;
    margin:0;
    padding:0;
  }
   .recent-chats li {
    padding:10px 32px;
    cursor:pointer;
    border-radius: 0;
    transition: .3s ease;
    white-space: nowrap; /* Prevents text from wrapping to the next line */
    overflow: hidden; /* Hides the overflowing text */
    text-overflow: ellipsis; /* Adds the ellipsis ("...") for overflowed text */
    font-size:15px;
    line-height: 13px;
    color:#1B2131E6;
    font-family: 'Circular Std Book';
    font-weight:normal;
    position: relative;
    margin-bottom:9px;
    border-radius: 10px;
  }
  .recent-chats li svg{
    position: absolute;
    right:15px;
  }
  .recent-chats li:hover {
    background-color:rgb(228, 228, 228);
    border-radius:10px
  }
  
  /* .recent-chats li.active {
    background-color: #592323;
    font-weight: bold;
  } */
  .previous, .today{
    font-weight: 600;
    font-size: 15px;
  }
  /* Custom Scrollbar */
.recent-chats::-webkit-scrollbar {
  width: 7px; /* Narrow scrollbar width */
}
/* 
.recent-chats::-webkit-scrollbar-track {
  
} */

.recent-chats::-webkit-scrollbar-thumb {
  background-color: #c3c3c3; /* Scrollbar color */
  border-radius: 10px; /* Rounded edges */
}

.recent-chats::-webkit-scrollbar-thumb:hover {
  background: #ffffff; /* Darker color on hover */
}
/* HTML: <div class="loader"></div> */
.loader {
  margin-top:50%;
  margin-left:30%;
  width: 40px;
  height: 20px;
  --c:no-repeat radial-gradient(farthest-side,#000 93%,#0000);
  background:
    var(--c) 0    0,
    var(--c) 50%  0;
  background-size: 8px 8px;
  position: relative;
  clip-path: inset(-200% -100% 0 0);
  animation: l6-0 1.5s linear infinite;
}
.loader:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 12px;
  background: #000;
  left: -16px;
  top: 0;
  animation: 
    l6-1 1.5s linear infinite,
    l6-2 0.5s cubic-bezier(0,200,.8,200) infinite;
}
.loader:after {
  content: "";
  position: absolute;
  inset: 0 0 auto auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #000; 
  animation: l6-3 1.5s linear infinite;
}
@keyframes l6-0 {
  0%,30%  {background-position: 0  0   ,50% 0   }
  33%     {background-position: 0  100%,50% 0   }
  41%,63% {background-position: 0  0   ,50% 0   }
  66%     {background-position: 0  0   ,50% 100%}
  74%,100%{background-position: 0  0   ,50% 0   }
}
@keyframes l6-1 {
  90%  {transform:translateY(0)}
  95%  {transform:translateY(15px)}
  100% {transform:translateY(15px);left:calc(100% - 8px)}
}
@keyframes l6-2 {
  100% {top:-0.1px}
}
@keyframes l6-3 {
  0%,80%,100% {transform:translate(0)}
  90%         {transform:translate(26px)}
}
svg{
  margin-left: 2px;
  margin-bottom: 2px;
  
}
.history-text{
  margin-bottom: 10px;
  font-size: 16px;
  margin-left: 12px;
  padding-top: -10px;
  font-family: Arial;
}

.feedback-buttons button:hover #Component_17_49 #Path_4440 {
  stroke: #006400;
  fill: #006400
}
.no-chat-tag{
  margin-left: 12%;
  top: 20%;
}

/* Animation for new chat entry */
@keyframes newChatAnim {
  0% {
    opacity: 0;
    transform: translateY(-20px);
    background-color: rgba(26, 74, 185, 0.1);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
    background-color: rgba(26, 74, 185, 0.1);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    background-color: transparent;
  }
}

.recent-chats li.new-chat-item {
  animation: newChatAnim 1.2s ease-out;
  position: relative;
  overflow: hidden;
}

/* Special styling for empty "New Chat" entries */
.recent-chats li.empty-new-chat {
  font-style: italic;
  color: #1A4AB9;
  font-weight: 500;
  cursor: pointer;
}

/* Animation for title updates */
@keyframes titleUpdateAnim {
  0% {
    color: #1A4AB9;
  }
  50% {
    color: #1A4AB9;
    font-weight: 500;
  }
  100% {
    color: inherit;
    font-weight: normal;
  }
}

.recent-chats li.title-update {
  animation: titleUpdateAnim 1.5s ease-out;
}