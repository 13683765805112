@media (max-width: 768px) {
    .sidebar {
      width: 100%; 
    }
  
    .user-profile {
      width: 40px;  
      height: 40px;
    }
  }
  /* .confirmation-dialog {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 10px;
  }
  
  .confirmation-dialog button {
    margin: 10px;
  } */
  
  .confirmation-dialog {
    position: fixed;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 300px;
    z-index: 1000;
  }
  
  .confirmation-dialog p {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .confirmation-dialog button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .confirmation-dialog button:first-child {
    background: #e74c3c;
    color: white;
  }
  
  .confirmation-dialog button:first-child:hover {
    background: #c0392b;
  }
  
  .confirmation-dialog button:last-child {
    background: #3498db;
    color: white;
  }
  
  .confirmation-dialog button:last-child:hover {
    background: #2980b9;
  }
  