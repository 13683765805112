.search-line {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 12px;
    background: transparent;
    z-index: 1000;
    transition: all 0.3s ease-in-out;   
    padding: 0;
    margin:0; 
    /* margin: 1vh; */
}

.search-line.bottom {
    width: 100%;
    max-width: 865px;    
    background: transparent;
    display: flex;
    margin:0 auto;
    flex-direction: column;
}
.text {
    display: flex;
    align-items: center;
    width: 100%;
    background: transparent;
    border-radius: 24px;
    padding: 8px 15px;
    border: 1px solid #ccc;
    transition: border 0.2s ease-in-out;
    position: relative;    
}
.text textarea{
    font-family:'Circular Std Book';
}

.text:focus-within {
    border-color: #007bff;
}

.small-box {    
    flex: 1;
    border: none;
    background-color: rgba(254, 254, 254, 0.992);
    font-size: 16px;
    padding-top: 8px; /* Moves cursor down */
    line-height: 1.5;
    /* color: #000; */
    outline: none;
    height: 35px;
    overflow-y: hidden;
    max-height: 150px; /* Set max height */
    resize: none;
    padding-right: 25px; /* Space for the fixed send button */
}
.small-box::-webkit-scrollbar {
    width: 8px; 
    background: transparent;
}

.small-box::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
    background: transparent; 
}

.small-box::-webkit-scrollbar-thumb {
    background: #bbb; 
    border-radius: 10px;
}

.small-box::-webkit-scrollbar-thumb:hover {
    background: #888; /* Darker grey on hover */
}

/* Scrollbar for Firefox */
.small-box {
    scrollbar-width: thin; /* Makes scrollbar thin */
    scrollbar-color: #bbb #f1f1f1; /* Thumb and track color */
}

.submit-btn {
    position: absolute;
    right: 10px;
    bottom: 7px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #007bff;
    border-radius: 50%;
    transition: background 0.2s ease-in-out;
}

.submit-btn img {
    width: 20px;
    height: 20px;
}

.submit-btn:hover {
    background: #818181;
}

.small-box::placeholder {
    color: rgba(0, 0, 0, 0.5); /* Optional: Lighter text color */
    font-size: 14px; /* Optional: Adjust size */
}
