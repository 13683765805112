.mainpage-container {
  display: flex;
  height: 100%;
  background-color: #fff;
}

.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0;
}

.chat-container .chat-container{padding:0;}


.chat-container .main-container .chat-container{ height:100%; margin-bottom:10px; margin-top:45px; overflow: hidden;}
