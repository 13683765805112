.App {
    text-align: center
}

.App-logo {
    height: 40vmin;
    pointer-events: none
}

@media (prefers-reduced-motion:no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #fff
}

.App-link {
    color: #61dafb
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0)
    }

    to {
        transform: rotate(360deg)
    }
}

.form-container {
    width: 300px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px
}

input {
    width: 100%;
    padding: 10px;
    margin: 10px 0
}

h2 {
    text-align: center
}

textarea {
    width: 100%;
    height: 60px;
    margin-bottom: 10px
}

button {
    cursor: pointer
}

.app-container {
    display: flex;
    flex-direction: column;
    height: 100vh
}

.app-container {
    display: flex;
    overflow: hidden;
    background-color: #f3f3f4;
    padding: 9px 9px 9px 0
}

.sidebar {
    width: 250px;
    background-color: #f0f0f0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.chat-window {
    flex-grow: 1;
    overflow-y: auto
}

.user-profile {
    padding: 0;
    position: absolute;
    right: 10px;
    top: -11px;
}

.user-profile p {
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 25px;
    border: 1px solid #1b2131;
    border-radius: 24px;
    font-size: 14px;
    line-height: 14px;
    color: #1b2131;
    padding: 9px 15px 9px 35px;
    font-family: "Circular Std Book";
    font-weight: 400;
    position: relative
}

.profile-initial {
    height: 32px;
    width: 32px;
    background-color: #1b2131;
    color: #fff;
    font-size: 21px;
    line-height: 36px;
    font-family: 'Circular Std Book';
    font-weight: 400;
    border-radius: 50%;
    display: grid;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -16px;
    z-index: 9;
    top: 25px
}

.user-profile img {
    width: 100%;
    height: auto
}

.login-btn {
    background-color: #00b868;
    color: #fff;
    font-family: 'Circular Std Book';
    font-weight: 400;
    border: 0;
    border-radius: 24px;
    font-size: 14px;
    line-height: 14px;
    padding: 9px 24px;
    margin-right: 8px
}

.signup-btn {
    background-color: #fff;
    color: #00b868;
    font-family: 'Circular Std Book';
    font-weight: 400;
    border: 0;
    border-radius: 24px;
    font-size: 14px;
    line-height: 14px;
    padding: 9px 24px;
    border: 1px solid #00b868
}